<template>
  <div class="contenido">
    <ImageHeader />
    <div class="is-10 container-data container">
        <div class="columns">
            <div class="is-4 column has-text-left go-back" @click="$router.go(-1)">
                <p class="title white bold"><font-awesome-icon class="icono" :icon="['fal', 'chevron-left']"/> {{ user.nombre }} {{ user.primer_apellido }} {{ user.segundo_apellido }}</p>
            </div>
            <div class="is-3 is-offset-5 column has-text-right">
                <button class="btn-go-profile btn button">
                    <router-link to="/evaluador"><span><font-awesome-icon class="icono" :icon="['fal', 'user']"/> <span class="text-l black">{{$t('EVALUATOR.GO_PROFILE')}}</span></span></router-link>
                </button>
            </div>
        </div>
        <div class="container is-10 data">
            <div class="columns">
                <div class="column is-10 is-offset-1">
                    <table class="table">
                        <thead>
                        <tr>
                            <th v-for="(column, index) in columns" :key="index" scope="col" :class="{'has-text-left' : column.leftTitle, 'has-text-centered': column.centerTitle, 'has-text-right': column.rightTitle}">
                                <b class="text-m">{{ $t(column.title) }}</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in evaluaciones" :key="index" class="text-m gray" @click="goStory(item.relato_id)">
                            <td class="has-text-centered contracte-field" style="padding-top: 1.6em;">
                                {{item.relato_id}}
                            </td>
                            <td class="has-text-left" style="padding-top: 1.6em;">{{ item.titulo }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";

export default {
  name: "evalueador",
  components: {
      ImageHeader
  },
  data() {
    return {
        columns: [{
            title: 'EVALUATOR.NUMBER',
            centerTitle: true
        }, {
            title: 'EVALUATOR.TITLE_WORK',
            leftTitle: true
        }],
        evaluaciones: [],
        user: {
            nombre: '',
            primer_apellido: '',
            segundo_apellido: '',        }
    };
  },
  created() {
        this.$store
          .dispatch("getEvaluaciones", {
            fase: this.$store.state.fases.fasesActuales[0].codigo,
            usuario_id: this.$route.params.usuarioId,
            provincia: this.$route.params.provincia,
            idioma: this.$i18n.locale
          })
          .then((evaluaciones) => {
            this.evaluaciones = evaluaciones;
            this.user.nombre = evaluaciones[0].nombre;
            this.user.primer_apellido = evaluaciones[0].primer_apellido;
            this.user.segundo_apellido = evaluaciones[0].segundo_apellido;
            this.$store.commit("setShowSpinner", false);
          })
          .catch((error) => {
            this.$store.commit("setShowSpinner", false);
          });
  },
  methods: {
      goStory(relatoId) {
          this.$router.push({ name: 'relato-otro', params: { usuarioId: this.$route.params.usuarioId, relatoId } })
      }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.go-back:hover{
    cursor: pointer;
}

.container-data{
    margin-top: -180px;
    padding-bottom: 100px;
}

.container.data{
    background-color: white;
}

.table {
    width: 100%;
    margin-bottom: 25px;
    border-collapse: separate;
    border-spacing: 0px 12px;
}


.table td, .table th {
    border: none;
    padding: 1em 1.25em;
}

.table tbody tr {
    background-color: $background-box;
    height: 60px;

    td:first-child {
        border-top-left-radius: 10px; 
        border-bottom-left-radius: 10px;
    }
    td:last-child {
        border-bottom-right-radius: 10px; 
        border-top-right-radius: 10px; 
    }
}

.table tbody tr:hover{
    cursor: pointer;
}

.btn-go-profile{
    background-color: white;
    border-radius: 20px;

    .icono{
        color: $primary;
        margin-right: 15px;
    }
}

.profileImage {
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 20px;
}
</style>
